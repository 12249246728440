<template>
    <div class="brandDetail">
        <swiper id="banner" ref="banner" class="swiper banner" :options="bannerOPtions">
            <swiper-slide v-for="item in banners" :key="item.id">
                <auto-img :src="item" v-if="checkImg(item)" mul="31.25%"></auto-img>
                <div class="video" v-else>
                    <video
                        :src="item"
                        controls
                        controlsList="nodownload noremote footbar"
                        style="height: 100%; max-width: 100%"
                        @playing="stopMove"
                        @pause="isPlay = false"
                        ref="video"
                        :poster="vendorInfo.video"
                        oncontextmenu="return false;"
                        onmouseleave="leaveVideo(this)"
                    >
                        {{ $t('nin-de-liu-lan-qi-bu-zhi-chi-video-biao-qian') }}
                    </video>

                    <el-button v-if="!isPlay" class="videoPlay iconfont2" @click="playVideo" type="text" size="default">
                        <img src="../../assets/icon_bofang.png" alt="" />
                    </el-button>
                </div>
            </swiper-slide>
            <div
                class="swiper-pagination swiper-pagination-right swiper-pagination-white"
                slot="pagination"
                v-siwperPagination
            ></div>
        </swiper>

        <div class="module-list">
            <div class="brand module" :style="{ order: decorationInfo.introductionOrder }">
                <div class="brandCenter">
                    <div class="introduce" v-html="introduction"></div>
                </div>
            </div>
            <div class="module category" v-if="categories.length > 0" :style="{ order: decorationInfo.productOrder }">
                <div class="brandCenter">
                    <title-driver class="hot-title">
                        <span slot="title">{{ $t('zhu-yao-chan-pin-fu-wu') }}</span>
                        <span slot="sub">product</span>
                    </title-driver>

                    <swiper class="service-swiper" :options="categoryOption">
                        <swiper-slide class="service" v-for="(item, index) in categories" :key="index">
                            <router-link
                                :to="{
                                    name: 'brandProductList',
                                    query: {
                                        vendorInfoId: vendorInfo.id
                                    }
                                }"
                            >
                                {{ getName(item) }}
                            </router-link>
                        </swiper-slide>

                        <div class="swiper-pagination" v-siwperPagination slot="pagination"></div>
                    </swiper>
                </div>
            </div>

            <div
                class="module module-1"
                v-if="applications.length > 0"
                :style="{ order: decorationInfo.applicationOrder }"
            >
                <div class="caseCenter">
                    <title-driver class="hot-title">
                        <span slot="title">{{ $t('ying-yong-ling-yu-1') }}</span>
                        <span slot="sub">Application field</span>
                    </title-driver>

                    <swiper
                        class="application-swiper"
                        :options="applicationOption"
                        :style="{
                            width: 170 * applications.length - 20 + 'px'
                        }"
                    >
                        <swiper-slide v-for="(item, index) in applications" :key="item.id">
                            <Application
                                :index="index"
                                :name="getName(item)"
                                :id="item.id"
                                :storeId="vendorInfo.id"
                                :img="item.img"
                            ></Application>
                        </swiper-slide>

                        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                    </swiper>
                </div>
            </div>

            <div class="module" v-if="cases.length > 0" :style="{ order: decorationInfo.caseOrder }">
                <div class="caseCenter">
                    <title-driver class="hot-title">
                        <span slot="title">{{ $t('cheng-gong-an-li') }}</span>
                        <span slot="sub">case</span>
                    </title-driver>
                    <swiper class="swiper2" ref="hotSwiper" :options="productOption">
                        <swiper-slide class="swiper-slide2" v-for="item in cases" :key="item.id">
                            <Case :name="item.title" :img="item.img" :id="item.id"></Case>
                        </swiper-slide>
                        <div class="swiper-pagination" v-siwperPagination slot="pagination"></div>
                    </swiper>
                </div>
            </div>

            <div class="module module-1" v-if="productList.length > 0" :style="{ order: decorationInfo.popularOrder }">
                <div class="productCenter">
                    <title-driver class="hot-title">
                        <span slot="title">{{ $t('re-men-chan-pin') }}</span>
                        <span slot="sub">Hot products</span>
                    </title-driver>

                    <swiper class="product-swiper" :options="informationOption" v-if="userInfo">
                        <swiper-slide v-for="item in productList" :key="item.id">
                            <ProductGride :info="item" :isNew="false" isHome></ProductGride>
                        </swiper-slide>
                        <div class="swiper-pagination" v-siwperPagination slot="pagination"></div>
                    </swiper>
                    <div class="hot-tips" v-else @click="login">{{ $t('deng-lu-ke-cha-kan-re-men-chan-pin') }}</div>
                </div>
            </div>

            <div class="vipImgsBox" :style="{ order: decorationInfo.customAreaOrder }">
                <div class="vipImgs">
                    <img
                        :src="item"
                        oncontextmenu="return false;"
                        v-for="(item, index) in customAreaImgs"
                        :key="index"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import Application from '../../components/brand/Application.vue';
import Case from '../../components/brand/Case';
import ProductGride from '../../components/product/Gride';
import { saveBrowse } from '../../utils/CommonApi';

export default {
    name: 'BrandDetail',
    metaInfo() {
        return {
            title: this.vendorInfo.id
                ? this.getName(this.vendorInfo, ['chCompanyName', 'enCompanyName'])
                : this.$t('imt-ke-ji-ping-tai')
        };
    },
    data() {
        return {
            productOption: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                slidesPerColumn: 1,
                spaceBetween: 20,
                slidesPerColumnFill: 'row',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'content-bullet',
                    bulletActiveClass: 'content-bullet-active'
                },
                centerInsufficientSlides: true
            },
            informationOption: {
                slidesPerView: 'auto',
                spaceBetween: 24,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    // type: 'progressbar',
                    bulletClass: 'progress-bullet',
                    bulletActiveClass: 'progress-bullet-active'
                }
            },
            applicationOption: {
                slidesPerView: 'auto',
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            categoryOption: {
                slidesPerView: 'auto',
                spaceBetween: 20,
                centerInsufficientSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    // type: 'progressbar',
                    bulletClass: 'progress-bullet',
                    bulletActiveClass: 'progress-bullet-active'
                }
            },
            decorationInfo: {},
            productList: [],
            listInfo: {
                productOrder: 'categories',
                applicationOrder: 'applications',
                caseOrder: 'cases',
                popularOrder: 'productList'
            },
            isPlay: false
        };
    },
    props: {
        vendorInfo: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        ...mapState(['userInfo']),
        banners() {
            if (this.vendorInfo.img) {
                return this.vendorInfo.img.split(',');
            }
            return [];
        },
        applications() {
            return this.vendorInfo.applications || [];
        },
        cases() {
            // return (
            //     this.vendorInfo.cases?.filter(item => {
            //         return !item.del;
            //     }) || []
            // );
            return this.vendorInfo.cases || [];
        },
        categories() {
            let customCategory = this.vendorInfo.customCategory
                ? this.getName(this.vendorInfo, ['customCategory', 'enCustomCategory']).split(',')
                : [];
            return [
                ...(this.vendorInfo.categories || []),
                ...customCategory.map(item => {
                    return { chName: item, enName: item };
                })
            ];
        },

        imgs() {
            if (this.vendorInfo.img) {
                return this.vendorInfo.img.split(',');
            } else {
                return [];
            }
        },
        bannerOPtions() {
            return {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                speed: 600,
                preventClicks: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'banner-bullet',
                    bulletActiveClass: 'banner-bullet-active'
                }
            };
        },
        hasVideo() {
            return !![...this.imgs].find(item => {
                return !this.checkImg(item);
            });
        },
        customAreaImgs() {
            if (this.decorationInfo.customArea) {
                return this.decorationInfo.customArea.split(',');
            } else {
                return [];
            }
        },
        showList() {
            const info = { ...this.listInfo };
            var list = [];
            Object.keys(info).forEach(item => {
                if (this[info[item]].length > 0) {
                    list.push(item);
                }
            });
            return list.sort((a, b) => {
                return this.decorationInfo[a] - this.decorationInfo[b];
            });
        },
        introduction() {
            return this.getName(this.vendorInfo, ['introduction', 'enIntroduction']).replace(/\n/g, '<br/>');
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.$emit('setStore', this.$route.query.id);
            this.$http
                .post(
                    '/decoration/all',
                    {
                        query: {
                            vendorInfoId: this.$route.query.id
                        }
                    },
                    { body: 'json' }
                )
                .then(res => {
                    if (res.numberOfElements > 0) {
                        this.decorationInfo = res.content[0];
                    }
                });

            this.$http.post('/decoration/hotProduct?vendorId=' + this.$route.query.id).then(res => {
                this.productList = res.filter(item => {
                    return item.deviceStatus === 'NOW_ON_SHELF';
                });
            });

            if (this.userInfo) {
                saveBrowse(this.$route.query.id, 'VENDOR');
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        Application,
        Case,
        ProductGride
    },
    methods: {
        stopMove() {
            this.isPlay = true;
            this.$refs.banner.$swiper.autoplay.stop();
        },
        checkImg(file) {
            const imglist = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'bmp', 'BMP', 'gif', 'GIF'];
            const _info = file.split('.');
            if (imglist.indexOf(_info[_info.length - 1]) !== -1) {
                return true;
            } else {
                return false;
            }
        },
        playVideo() {
            this.$refs.video[0].play();
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.brandDetail {
    .swiper-pagination-right {
        width: auto;
        left: auto;
        right: 30px;
    }
}

video::-internal-media-controls-download-button {
    display: none;
}
video::-webkit-media-controls-enclosure {
    overflow: hidden;
}
video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
}

.swiper2 {
    padding-bottom: 60px;
}

.banner {
    width: 100%;
}

.brand {
    background-color: #fff;
    padding: 70px 0 !important;
}

.brandCenter {
    @include center-content(570);
}

.caseCenter {
    @include center-content(460);
}

.introduce {
    font-size: 14px;
    color: #292c33;
    line-height: 24px;
    text-align: center;
    // padding: 100px 0 0;
}

.module {
    padding: 140px 0;

    background-color: #fff;
    &.module-1 {
        background-color: #f5f7fa;
    }

    .hot-title {
        padding-bottom: 60px;
    }

    &.category {
        padding: 140px 0 70px;
    }
}

$colors: [#6060b3, #607cb3, #6097b3, #60b397, #a2b360, #B39A60];
.service-swiper {
    padding-bottom: 70px;
    .swiper-pagination {
        bottom: 40px;
        left: 50%;
        transform: translate(-50%);
    }
}
.service {
    font-size: 14px;
    color: #ffffff;
    line-height: 60px;
    // min-width: 124px;
    padding: 0 20px;
    height: 60px;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
    width: auto;
    cursor: pointer;

    a {
        color: #ffffff;
        width: 100%;
        height: 100%;
    }

    @each $color in $colors {
        $index: index($colors, $color);
        &:nth-child(6n + #{$index}) {
            background-color: $color;

            &:hover {
                background-color: mix($--color-black, $color, $--button-active-shade-percent);
            }
        }
    }
}

.applicationsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.application-swiper {
    max-width: 100%;
    .swiper-slide {
        width: 150px;
    }

    .swiper-button-prev {
        position: absolute;
    }
}
.applications {
    display: flex;
    // justify-content: center;
    overflow-x: auto;
    flex-wrap: nowrap;
    max-width: 100%;

    // .application + .application {
    //     margin-left: 20px;
    // }
}
.productCenter {
    @include center-content(360, 1920, 0px);

    .hot-tips {
        text-align: center;
        font-size: 18px;
        color: #bcc1cc;
        padding: 20px 0;
        cursor: pointer;
        &:hover {
            color: #000;
            text-decoration: underline;
        }
    }
}

.product-swiper {
    padding: 0 50px 60px;
    // padding-right: $--page-padding + 20;
    // padding-left: $--page-padding;
    // padding-bottom: 60px;
    .swiper-slide {
        width: 240px;
        padding: 30px 0;
    }

    .swiper-pagination {
        top: auto;
        bottom: 0px;
    }
}

// @media screen and (min-width: 1690px) {
//     .product-swiper {
//         padding-left: calc(50% - 795px);
//     }
// }
.vipImgsBox {
    background-color: #ffff;
}
.vipImgs {
    @include center-content(520, 1920, 0px);
    padding-top: 80px;
    padding-bottom: 80px;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}
.video {
    background-color: #000;
    height: 31.25vw;
    min-height: 1200px * 0.3125;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    video {
        width: 100%;
        height: 100%;
    }

    .videoPlay {
        position: absolute;
        z-index: 90;
        img {
            width: 90px;
            height: 90px;
            display: block;
            opacity: 0.5;
        }

        &:hover {
            img {
                opacity: 1;
            }
        }
    }
}

.module-list {
    display: flex;
    flex-direction: column;
}
</style>
<style lang="scss">
.application-swiper {
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
        opacity: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
        width: 50px;
        height: 100%;
        top: calc(var(--swiper-navigation-size) / 2);
        opacity: 0;
        transition: opacity ease-in-out 0.3s;

        // background-color: rgba($color: #000000, $alpha: 0.3);
    }

    &:hover {
        .swiper-button-prev,
        .swiper-button-next {
            opacity: 1;
        }
        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
            opacity: 0;
        }
    }
}
</style>
